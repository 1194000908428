/**
 * Nosy Index Contact Page Template
 *
 * @export ContactPage
 */
import {
    Alert,
    AlertDescription,
    AlertIcon,
    AlertTitle,
    Center,
    Container,
    Flex,
    Heading,
    SimpleGrid,
    Skeleton,
    Stack,
    Text,
    useToken
} from '@chakra-ui/react'
import { PortableText, toPlainText } from '@portabletext/react'
import { GoogleMap, Marker, useLoadScript } from '@react-google-maps/api'
import { graphql } from 'gatsby'
import React from 'react'
import MapStyles from '../../@chakra-ui/gatsby-plugin/components/map-styles'
import Layout from '../../components/layout/layout'
import Link from '../../components/link/link'
import SEO from '../../components/seo/seo'
import SocialIcons from '../../components/social-icons/social-icons'
import { useSiteSettings } from '../../hooks/use-site-settings'
import contactSerializer from '../../serializers/body/contact-serializer'
import { formatFunctionalPhoneNumber } from '../../utils/helpers'

// Query variables passed in from pageContext
export const query = graphql`
    query TEMPLATE_CONTACT_QUERY($id: String!) {
        sanityPage(id: { eq: $id }) {
            title
            seo: _rawSeo(resolveReferences: { maxDepth: 10 })
            slug {
                current
            }
            heading: _rawHeading(resolveReferences: { maxDepth: 10 })
            body: _rawBody(resolveReferences: { maxDepth: 10 })
            # excerpt: _rawExcerpt(resolveReferences: { maxDepth: 10 })
        }
    }
`

const ContactPage = ( props ) => {
    // console.log( '🦄 ~ file: contact.js ~ line 49 ~ ContactPage ~ props', { props } )

    // Destructure props
    const {
        data: {
            sanityPage: {
                seo: { seoTitle = '', seoDescription = '' },
                heading = [],
                body = []
                // excerpt = []
            }
        }
    } = props

    // Grab gallery images & office location from Sanity SiteSettings
    const {
        contactPoints: { email = '', phone = '', address = '', location = {} },
        socialUrls = []
    } = useSiteSettings()

    // Grab color values from Chakra UI theme for map options
    const [brandPrimary, brandGrayscaleDarker] = useToken( 'colors', [
        'brand_primary.700',
        'brand_grayscale.darker'
    ] )

    // Instantiate map styles
    const mapStyles = MapStyles()

    // Instantiate Google Maps API
    const { isLoaded, loadError } = useLoadScript( {
        googleMapsApiKey: process.env.GATSBY_GOOGLE_MAPS_API_KEY,
        preventGoogleFontsLoading: false
    } )

    return (
        <Layout>
            <SEO
                title={seoTitle}
                description={seoDescription} />
            <Container
                maxWidth='6xl'
                mb={[20]}
                centerContent>
                <Center
                    flexDirection='column'
                    maxWidth='3xl'>
                    <Heading
                        as='h1'
                        maxWidth='5xl'
                        fontSize={['5xl', '4xl', '6xl', '7xl']}
                        textAlign='center'
                        mt={12}
                        mb={24}
                        color='gray.100'
                        fontWeight='extrabold'>
                        {toPlainText( heading )}
                    </Heading>
                </Center>
            </Container>

            <Container
                maxWidth='3xl'
                pb={24}
                centerContent>
                <Stack
                    direction='column'
                    spacing={12}>
                    <PortableText
                        value={body}
                        components={contactSerializer} />
                </Stack>
            </Container>

            <Container
                maxWidth='full'
                my={48}
                px={0}>
                <SimpleGrid
                    columns={[1, null, 2]}
                    spacing={[12, null, 28]}>
                    <Stack
                        direction='column'
                        spacing={6}
                        order={[2, 1]}>
                        {loadError ? (
                            <Alert
                                status='error'
                                variant='left-accent'
                                maxWidth='xl'
                                mx='auto'>
                                <AlertIcon />
                                <AlertTitle mr={2}>Oh noes!</AlertTitle>
                                <AlertDescription>
                                    Error loading map data
                                </AlertDescription>
                            </Alert>
                        ) : (
                            <Flex
                                justifyContent='center'
                                alignItems='center'
                                width='full'>
                                {isLoaded ? (
                                    <GoogleMap
                                        mapContainerStyle={{
                                            width: '100%',
                                            height: '700px'
                                        }}
                                        zoom={11}
                                        clickableIcons={true}
                                        center={{
                                            lat: location.lat,
                                            lng: location.lng
                                        }}
                                        options={{
                                            backgroundColor:
                                                brandGrayscaleDarker,
                                            disableDefaultUI: true,
                                            styles: mapStyles
                                        }}>
                                        <Marker
                                            icon={{
                                                path: 'M172.268 501.67C26.97 291.031 0 269.413 0 192 0 85.961 85.961 0 192 0s192 85.961 192 192c0 77.413-26.97 99.031-172.268 309.67-9.535 13.774-29.93 13.773-39.464 0zM192 272c44.183 0 80-35.817 80-80s-35.817-80-80-80-80 35.817-80 80 35.817 80 80 80z',
                                                fillColor: brandPrimary,
                                                fillOpacity: 1,
                                                scale: 0.075,
                                                // eslint-disable-next-line no-undef
                                                anchor: new google.maps.Point(
                                                    185,
                                                    500
                                                )
                                            }}
                                            position={{
                                                lat: location.lat,
                                                lng: location.lng
                                            }} />
                                    </GoogleMap>
                                ) : (
                                    <Skeleton
                                        startColor='brand_primary.700'
                                        endColor='brand_grayscale.darker'
                                        width='full'
                                        height='700px'
                                        speed={1} />
                                )}
                            </Flex>
                        )}
                    </Stack>
                    <Stack
                        direction='column'
                        spacing={24}
                        px={[6, null, 0]}
                        order={[1, 2]}>
                        <Stack
                            direction='column'
                            spacing={6}>
                            <Heading as='h3'>Get in touch</Heading>
                            <Flex direction='column'>
                                <Stack
                                    direction='column'
                                    spacing={2}
                                    mb={8}>
                                    <Link
                                        to={`mailto:${email}`}
                                        color='gray.100'
                                        fontSize={['xl', '2xl']}>
                                        {email}
                                    </Link>
                                    <Link
                                        to={`tel:${formatFunctionalPhoneNumber(
                                            phone
                                        )}`}
                                        color='gray.100'
                                        fontSize={['xl', '2xl']}>
                                        {phone}
                                    </Link>
                                </Stack>
                                <Text
                                    color='gray.100'
                                    fontSize={['xl', '2xl']}>
                                    {address}
                                </Text>
                            </Flex>
                            <Flex>
                                <SocialIcons
                                    platforms={socialUrls}
                                    mb={[10, 8, 6]} />
                            </Flex>
                        </Stack>
                    </Stack>
                </SimpleGrid>
            </Container>

            <Container
                maxWidth='6xl'
                mb={[20]}
                centerContent>
                <Center
                    flexDirection='column'
                    maxWidth='3xl'>
                    <Heading
                        as='h2'
                        maxWidth='5xl'
                        fontSize={['5xl', '4xl', '6xl', '7xl']}
                        textAlign='center'
                        mt={12}
                        mb={24}
                        color='gray.100'
                        fontWeight='extrabold'>
Travel
                    </Heading>
                </Center>
            </Container>

            <Container
                maxWidth='5xl'
                mb={[20]}>
                <SimpleGrid
                    columns={[1, null, 2]}
                    spacing={[12, null, 28]}>
                    <Stack
                        direction='column'
                        spacing={6}
                        order={[2, 1]}>
                        <Heading as='h3'>Cycling</Heading>
                        <Text
                            color='gray.100'
                            fontSize={['xl']}>On site there is a bike storage facility where you can safely store your bicycle.<br />There are showers on site to refresh after the journey.</Text>
                    </Stack>
                    <Stack
                        direction='column'
                        spacing={6}
                        order={[2, 1]}>
                        <Heading as='h3'>Walking</Heading>
                        <Text
                            color='gray.100'
                            fontSize={['xl']}>Building 41 is walkable from Somerton Park and Ride Car Park (8 minutes), Northwood Village (10 minutes) and Cowes Town (30 minutes).</Text>
                    </Stack>
                    <Stack
                        direction='column'
                        spacing={6}
                        order={[2, 1]}>
                        <Heading as='h3'>Bus</Heading>
                        <Text
                            color='gray.100'
                            fontSize={['xl']}>The Number 1 bus (Park and Ride) stops right outside the building at the ‘BAE’ bus stop.</Text>
                    </Stack>
                    <Stack
                        direction='column'
                        spacing={6}
                        order={[2, 1]}>
                        <Heading as='h3'>Parking</Heading>
                        <Text
                            color='gray.100'
                            fontSize={['xl']}>On-site parking includes a disabled bay and two electric vehicle charging spots.</Text>
                    </Stack>
                </SimpleGrid>
            </Container>
        </Layout>
    )
}

export default ContactPage
