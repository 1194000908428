/**
 * Nosy Sanity.io Block - contact Serializer
 *
 * @export contactSerializer
 */

import { AspectRatio, Center, chakra, Heading, ListItem, OrderedList, Skeleton, Stack, Text, UnorderedList } from '@chakra-ui/react'
import React, { useState } from 'react'
import Image from '../../components/image/image'
import ReactPlayer from 'react-player/youtube'
import InstagramEmbed from 'react-instagram-embed'
import { useSiteMetadata } from '../../hooks/use-site-metadata'
import Link from '../../components/link/link'

const contactSerializer = {
    types: {
        mainImage: ( props ) => {
            // console.log( '🦄 ~ file: default-serializers.js ~ line 25 ~ props', { props } )

            const {
                value = {}
            } = props
            
            return (
                <Stack 
                    direction='column' 
                    spacing={4} 
                    my={12} 
                    alignItems='center'>
                    <Image
                        imageData={value}
                        width={800} />
                    <Text 
                        fontSize='xl' 
                        textAlign='center' 
                        color='gray.400'>
                        {value?.caption}
                    </Text>
                </Stack>
            )
        },
        video: ( props ) => {
            // console.log( '🦄 ~ file: default-serializers.js ~ line 48 ~ props', { props } )
            const {
                value: { url = '' }
            } = props
                
            // Setup loading state
            // eslint-disable-next-line react-hooks/rules-of-hooks
            const [loading, setLoading] = useState( true )
                
            const ChakraReactPlayer = chakra( ReactPlayer, {
                shouldForwardProp: ( prop ) => prop
            } )
    
            return (
                <Stack 
                    direction='column' 
                    spacing={4} 
                    mx={[0, null, -8]}
                    my={12}>
                    <AspectRatio ratio={16 / 9}>
                        <Skeleton
                            isLoaded={!loading}
                            startColor='brand_primary.700'
                            endColor='brand_grayscale.darker'
                            width='full'
                            speed={1}>
                            <ChakraReactPlayer 
                                onReady={() => setLoading( false )}
                                url={url} 
                                playing={true}
                                muted={true}
                                controls={true}
                                width='100%' 
                                height='100%' />
                        </Skeleton>
                    </AspectRatio>
                </Stack>
            )
        },
        instagram: ( props ) => {
            // console.log('🦄 ~ file: default-serializers.js ~ line 88 ~ props', { props })

            const ChakraInstagramEmbed = chakra( InstagramEmbed, {
                shouldForwardProp: ( prop ) => prop
            } )

            return (
                <Center>
                    <ChakraInstagramEmbed
                        my={4}
                        width='full'
                        url={props.node.url}
                        clientAccessToken={`${process.env.GATSBY_FACEBOOK_APP_ID}|${process.env.GATSBY_FACEBOOK_CLIENT_ID}`}
                        maxWidth={600}
                        hideCaption={true}
                        protocol=''
                        injectScript
                        onLoading={() => {}}
                        onSuccess={() => {}}
                        onAfterRender={() => {}}
                        onFailure={() => {}} />
                </Center>
            )
        },
        teamReference: ( props ) => {
            // console.log( '🦄 ~ file: post-serializers.js ~ line 172 ~ props', { props } )
    
            // Get siteUrl to build correct urls
            // eslint-disable-next-line react-hooks/rules-of-hooks
            const { siteUrl = '' } = useSiteMetadata()
    
            // Destructure teamReference content fields
            const {
                value : {
                    team : {
                        _type: type  = '',
                        name = '',
                        current = false,
                        slug = {}
                    }
                }
            } = props

            if ( current === true ) {
                return (
                    <Link
                        to={`${siteUrl}/${type}/${slug.current}/`}
                        display='inline'
                        color='brand_secondary.500'
                        fontSize='inherit'
                        borderBottom='1px'
                        borderColor='transparent'
                        _hover={{
                            borderBottomWidth: '1px',
                            borderBottomStyle: 'solid',
                            borderBottomColor: 'brand_primary.700'
                        }}>
                        {name}
                    </Link>
                )
            }

            return (
                <Text 
                    color='gray.100'
                    display='inline'
                    fontSize='inherit'>
                    {name}
                </Text>
            )
        }
    },
    marks: {
        strong: ( { children } ) => (
            <Text
                as='strong'
                fontFamily='Modelica-Bold'
                lineHeight='1.2'
                fontWeight='bold'
                letterSpacing='tight'
                fontSize={['3xl', '4xl']}
                color='gray.100'>
                {children}
            </Text>
        ),
        em: ( { children } ) => (
            <Text
                as='em'
                fontFamily='Modelica-Bold'
                lineHeight='1.2'
                fontWeight='bold'
                letterSpacing='tight'
                fontSize={['3xl', '4xl']}
                color='gray.100'>
                {children}
            </Text>
        ),
        link: ( props ) => {
            // console.log( '🦄 ~ file: serializers.js ~ line 104 ~ props', { props } )
            const {
                children,
                value: { href = '', blank = false }
            } = props
            return (
                <Link
                    to={href}
                    target={ blank ? '_blank' : null }
                    display='inline'
                    color='brand_secondary.500'
                    fontFamily='Modelica-Bold'
                    fontSize={['3xl', '4xl']}
                    borderBottom='3px'
                    borderColor='transparent'
                    _hover={{
                        borderBottomWidth: '3px',
                        borderBottomStyle: 'solid',
                        borderBottomColor: 'brand_primary.700'
                    }}>
                    {children}
                </Link>
            )
        },
    },
    block: {
        h1: ( { children } ) => (
            <Heading
                as='h1'
                lineHeight='1.2'
                fontWeight='bold'
                letterSpacing='tight'
                fontFamily='Modelica-Bold'
                fontSize={['3xl', '4xl']}
                color='gray.100'>
                {children}
            </Heading>
        ),
        h2: ( { children } ) => (
            <Heading
                as='h2'
                lineHeight='1.2'
                fontWeight='bold'
                letterSpacing='tight'
                fontFamily='Modelica-Bold'
                fontSize={['3xl', '4xl']}
                color='gray.100'>
                {children}
            </Heading>
        ),
        h3: ( { children } ) => (
            <Heading
                as='h3'
                lineHeight='1.2'
                fontWeight='bold'
                letterSpacing='tight'
                fontFamily='Modelica-Bold'
                fontSize={['3xl', '4xl']}
                color='gray.100'>
                {children}
            </Heading>
        ),
        h4: ( { children } ) => (
            <Heading
                as='h4'
                lineHeight='1.2'
                fontWeight='bold'
                letterSpacing='tight'
                fontFamily='Modelica-Bold'
                fontSize={['3xl', '4xl']}
                color='gray.100'>
                {children}
            </Heading>
        ),
        h5: ( { children } ) => (
            <Heading
                as='h5'
                lineHeight='1.2'
                fontWeight='bold'
                letterSpacing='tight'
                fontFamily='Modelica-Bold'
                fontSize={['3xl', '4xl']}
                color='gray.100'>
                {children}
            </Heading>
        ),
        h6: ( { children } ) => (
            <Heading
                as='h6'
                lineHeight='1.2'
                fontWeight='bold'
                letterSpacing='tight'
                fontFamily='Modelica-Bold'
                fontSize={['3xl', '4xl']}
                color='gray.100'>
                {children}
            </Heading>
        ),
        normal: ( { children } ) => (
            <Text
                lineHeight='1.2'
                fontWeight='bold'
                letterSpacing='tight'
                fontFamily='Modelica-Regular'
                fontSize={['2xl', '3xl']}
                textAlign='center'
                color='gray.100'>
                {children}
            </Text>
        )
    },
    list: {
        bullet: ( { children } ) => ( 
            <UnorderedList 
                mx='auto'
                sx={{
                    'p + &' : {
                        marginTop: -6
                    }
                }}>
                {children}
            </UnorderedList> 
        ),
        number: ( { children } ) => ( 
            <OrderedList 
                mx='auto'
                sx={{
                    'p + &' : {
                        marginTop: -6
                    }
                }}>
                {children}
            </OrderedList> 
        )
    },
    listItem: ( { children } ) => ( 
        <ListItem
            ml={6}
            fontSize={['3xl', '4xl']}
            lineHeight='1.2'
            fontFamily='Modelica-Bold'
            sx={{
                '&::marker': {
                    color: 'brand_primary.700'
                }
            }}>
            {children}
        </ListItem> 
    )
}

export default contactSerializer